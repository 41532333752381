<template>
  <validation-provider
    #default="{ errors }"
    name="nfPayed"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      label="NF Paga?"
      label-for="nfPayed"
    >
      <v-select
        id="nfPayed"
        v-model="nfPayed"
        :class="errors.length > 0 ? 'error-select' : ''"
        :options="nfPayedOptions"
        :placeholder="'NF Paga?'"
        :disabled="readonly"
        :clearable="!readonly"
      >
        <template #option="{ label }">
          {{ label }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'nfPayedValue',
    event: 'nfPayedChange',
  },
  props: {
    nfPayedValue: {
      type: [Object, Boolean],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      nfPayedOptions: [
        { label: 'SIM', value: true },
        { label: 'NÃO', value: false },
      ],
    }
  },
  computed: {
    nfPayed: {
      get() {
        return this.nfPayedValue
      },
      set(value) {
        const valueMap = {
          true: { label: 'SIM', value: true },
          false: { label: 'NÃO', value: false },
        }

        this.$emit('nfPayedChange', valueMap[value] || value)
      },
    },
  },
  watch: {
    nfPayedValue(newVal) {
      this.nfPayed = newVal
    },
  },
}
</script>

<style lang="scss">

.v-select {
  background-color: white;
}
</style>
