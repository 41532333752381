<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-body>
      <b-row>
        <b-col
          v-if="isFieldEnabled('zip_code')"
          cols="12"
          :md="isFieldEnabled('address_type') ? 3 : 4"
        >
          <validation-provider
            #default="{ errors }"
            name="zip_code"
            :rules="{
              required: isFieldRequired('zip_code'),
              min:7,
              regex:/^\d{5}-\d{3}$/
            }"
          >
            <b-form-group
              label="CEP"
              label-for="zip_code"
            >
              <cleave
                id="additional_zip_code"
                v-model.lazy="address[zipCodeField]"
                class="form-control"
                :raw="false"
                :options="options.cepDelimiter"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                placeholder="00000-000"
                :readonly="isViewMode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('address_type')"
          cols="12"
          md="3"
        >
          <address-type-select
            id="additional_addressType"
            v-model="address.additional_address_type_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('address_type')"
            @ready="syncLoad.ready('address_type')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('address')"
          cols="12"
          :md="isFieldRequired('address_type') ? 6 : 8"
        >
          <validation-provider
            #default="validationContext"
            name="address"
            :rules="{
              required: isFieldRequired('address'),
            }"
          >
            <b-form-group
              label="Endereço"
              label-for="address"
            >
              <b-form-input
                id="additional_address"
                v-model="address[addressField]"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Endereço"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          v-if="isFieldEnabled('number')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="number"
            :rules="{
              required: isFieldRequired('number'),
            }"
          >
            <b-form-group
              label="Número"
              label-for="number"
            >
              <b-form-input
                id="additional_number"
                v-model="address[numberField]"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="000"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('complement')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="complement"
            :rules="{
              required: isFieldRequired('complement'),
            }"
          >
            <b-form-group
              label="Complemento"
              label-for="complement"
            >
              <b-form-input
                id="additional_complement"
                v-model="address[complementField]"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Complemento"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('neighborhood_type')"
          cols="12"
          md="4"
        >
          <neighborhood-type-select
            id="additional_neighborhoodType"
            v-model="address.additional_neighborhood_type_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('neighborhood_type')"
            @ready="syncLoad.ready('neighborhood_type')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('neighborhood')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="neighborhood"
            :rules="{
              required: isFieldRequired('neighborhood'),
            }"
          >
            <b-form-group
              label="Bairro"
              label-for="neighborhood"
            >
              <b-form-input
                id="additional_neighborhood"
                v-model="address[neighborhoodField]"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Bairro"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isFieldEnabled('country')"
          cols="12"
          :md="isFieldEnabled('region_freight') ? 3 : 4"
        >
          <country-select
            id="additional_country"
            v-model="address[`${countryField}_set`]"
            :readonly="isViewMode"
            :required-field="isFieldRequired('country')"
            @ready="syncLoad.ready('country')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('state')"
          cols="12"
          :md="isFieldEnabled('region_freight') ? 3 : 4"
        >
          <state-select
            id="additional_state"
            v-model="address[`${stateField}_set`]"
            :readonly="isViewMode || !address[countryField]"
            :required-field="isFieldRequired('state')"
            :country="address[countryField]"
            @ready="syncLoad.ready('state')"
          />
        </b-col>

        <b-col
          v-if="isFieldEnabled('city')"
          cols="12"
          :md="isFieldEnabled('region_freight') ? 3 : 4"
        >
          <city-select
            id="additional_city"
            v-model="address[`${cityField}_set`]"
            :readonly="isViewMode || !address[stateField]"
            :required-field="isFieldRequired('city')"
            :state="address[stateField]"
            @ready="syncLoad.ready('city')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('region_freight')"
          cols="12"
          md="3"
        >
          <region-freight-select
            id="additional_region_freight"
            v-model="address.additional_region_freight_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('region_freight')"
            @ready="syncLoad.ready('region_freight')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import {
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import CountrySelect from '@/components/address/CountrySelect.vue'
import StateSelect from '@/components/address/StateSelect.vue'
import CitySelect from '@/components/address/CitySelect.vue'
import AddressTypeSelect from '@/components/address/AddressTypeSelect.vue'
import NeighborhoodTypeSelect from '@/components/address/NeighborhoodTypeSelect.vue'
import RegionFreightSelect from '@/components/transport/RegionFreightSelect.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    Cleave,
    CountrySelect,
    StateSelect,
    CitySelect,
    AddressTypeSelect,
    NeighborhoodTypeSelect,
    RegionFreightSelect,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    isViewMode: {
      type: Boolean,
      required: true,
    },
    requiredFields: {
      type: Array,
      required: true,
    },
    enabledFields: {
      type: Array,
      required: false,
      default() {
        return [
          'zip_code',
          'address',
          'number',
          'complement',
          'neighborhood',
          'state',
          'city',
          'country',
        ]
      },
    },
    zipCodeField: {
      type: String,
      required: false,
      default: 'additional_zip_code',
    },
    addressField: {
      type: String,
      required: false,
      default: 'additional_address',
    },
    numberField: {
      type: String,
      required: false,
      default: 'additional_number',
    },
    complementField: {
      type: String,
      required: false,
      default: 'additional_complement',
    },
    neighborhoodField: {
      type: String,
      required: false,
      default: 'additional_neighborhood',
    },
    countryField: {
      type: String,
      required: false,
      default: 'additional_country',
    },
    stateField: {
      type: String,
      required: false,
      default: 'additional_state',
    },
    cityField: {
      type: String,
      required: false,
      default: 'additional_city',
    },
  },
  data() {
    const syncLoad = new SyncLoad()

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
          required: true,
        },
      },
      syncLoad,
    }
  },
  watch: {
    'address.additional_country_set': function updateAdditionalCountry(val) {
      if (this.address.additional_country_set) {
        this.watchCountry(val)
      }
    },
    'address.country_set': function updateCountry(val) {
      if (this.address.country_set && !this.address.additional_country_set) {
        this.watchCountry(val)
      }
    },
    'address.additional_state_set': function updateAdditionalState(val) {
      if (this.address.additional_state_set) {
        this.watchState(val)
      }
    },
    'address.state_set': function updateState(val) {
      if (this.address.state_set && !this.address.additional_state_set) {
        this.watchState(val)
      }
    },
    'address.additional_city_set': function updateAdditionalCity(val) {
      if (this.address.additional_city_set) {
        this.watchCity(val)
      }
    },
    'address.city_set': function updateCity(val) {
      if (this.address.city_set && !this.address.additional_city_set) {
        this.watchCity(val)
      }
    },
    'address.additional_address_type_set': function updateAdditionalAddressType(val) {
      if (val) {
        this.address.additional_address_type = val.id
        this.address.additional_address_type_set = val
      } else {
        this.address.additional_address_type = ''
        this.address.additional_address_type_set = ''
      }
    },
    'address.additional_neighborhood_type_set': function updateAdditionalNeighborhoodType(val) {
      if (val) {
        this.address.additional_neighborhood_type = val.id
      } else {
        this.address.additional_neighborhood_type = ''
      }
    },
    'address.additional_region_freight_set': function updateAdditionalRegionFreight(val) {
      if (val) {
        this.address.additional_region_freight = val.id
      } else {
        this.address.additional_region_freight = ''
      }
    },
  },
  created() {
    this.checkEnabledFields()
  },
  methods: {
    isFieldRequired(field) {
      return this.requiredFields.includes(field)
    },
    isFieldEnabled(field) {
      return this.enabledFields.includes(field)
    },
    watchCountry(val) {
      if (val) {
        this.address[this.countryField] = val.id
        this.address[`${this.countryField}_set`] = val
      } else {
        this.address[this.countryField] = ''
        this.address[`${this.countryField}_set`] = ''
        this.address[this.stateField] = ''
        this.address[`${this.stateField}_set`] = ''
        this.address[this.cityField] = ''
        this.address[`${this.cityField}_set`] = ''
      }
    },
    watchState(val) {
      if (val) {
        this.address[this.stateField] = val.id
      } else {
        this.address[this.stateField] = ''
        this.address[`${this.stateField}_set`] = ''
        this.address[this.cityField] = ''
        this.address[`${this.cityField}_set`] = ''
      }
    },
    watchCity(val) {
      if (val) {
        this.address[this.cityField] = val.id
      } else {
        this.address[this.cityField] = ''
      }
    },
    checkEnabledFields() {
      const syncFields = [
        'address_type',
        'neighborhood_type',
        'country',
        'state',
        'city',
        'region_freight',
      ]

      let syncFieldEnabled = false

      syncFields.forEach(field => {
        if (this.isFieldEnabled(field)) {
          this.syncLoad.addTrigger(field)
          syncFieldEnabled = true
        }
      })

      if (!syncFieldEnabled) {
        this.$emit('ready', true)
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
