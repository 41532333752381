var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"owner","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.labelField,"label-for":"owner"}},[_c('vue-autosuggest',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"ownerInput","no-filter":"","suggestions":_vm.ownersOptions,"input-props":{
        id: 'owner',
        ref: 'owner',
        class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
        placeholder: _vm.labelField,
        readonly: _vm.readonly,
      },"limit":_vm.limitElements,"get-suggestion-value":_vm.getOwnerSelected},on:{"input":_vm.onInputOwnerChange,"selected":_vm.onSelectedOwner,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"id":"biddingOwnerImage","src":_vm.userImages[suggestion.item.get_uuid],"size":"2.5rem"}})]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"mb-0 d-flex"},[_vm._v(" "+_vm._s(suggestion.item.first_name)+" ")])])],1),_c('b-row',[_c('b-col',[_c('small',{},[_vm._v(" "+_vm._s(suggestion.item.permission_set.name)+" ")])])],1)],1)]}}],null,true),model:{value:(_vm.ownerSearch),callback:function ($$v) {_vm.ownerSearch=$$v},expression:"ownerSearch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }