<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        class="position-relative orderTableView"
        primary-key="id"
        responsive
        show-empty
        striped
        hover
        empty-text="Não há pedidos cadastrados."
        :items="dataList"
        :fields="visibleFields"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        :busy="isLoading"
        @row-clicked="onRowSelectedForDetail"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>
        </template>
        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'order-detail', params: { id: field.item.id } } "
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(contract__bidding__client__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.contract_set.bidding_set.client_set.name }}
          </div>
        </template>

        <template #cell(contract__bidding__uasg)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.contract_set.bidding_set.uasg }}
          </div>
        </template>

        <template #cell(contract__bidding__trade_number)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.contract_set.bidding_set.trade_number }}
          </div>
        </template>

        <template #cell(company__name_fantasy)="field">
          <div
            class="d-block text-nowrap"
          >
            <span v-if="field.item.company_set">
              {{ field.item.company_set.name_fantasy }}
            </span>
            <span v-else>
              -
            </span>
          </div>
        </template>

        <template #cell(price)="field">
          <div
            class="d-block text-nowrap"
          >
            R$ {{ field.item.price | formatCurrency() }}
          </div>
        </template>

        <template #cell(price_commitment)="field">
          <div
            class="d-block text-nowrap"
          >
            R$ {{ field.item.price_commitment | formatCurrency() }}
          </div>
        </template>

        <template #cell(interest__name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.interest_set.color}"
            >
              {{ field.item.interest_set.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(nf_paid)="field">
          <div
            v-if="field.item.nf_payed"
            class="d-block text-nowrap"
          >
            SIM
          </div>
          <div
            v-else
            class="d-block text-nowrap"
          >
            NÃO
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BBadge,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import exportFromJSON from 'export-from-json'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BBadge,
    BSpinner,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'orderValue',
    event: 'orderValueChange',
  },
  props: {
    orderValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    filter: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const totalPages = 0
    const currentPage = 1

    const sortOptions = {
      sortBy: 'id',
      isSortDirDesc: true,
    }

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap',
      },
      {
        key: 'contract__bidding__client__name',
        label: 'Órgão / Instituição',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'contract__bidding__uasg',
        label: 'UASG',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'contract__bidding__trade_number',
        label: 'Nº Pregão',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'company__name_fantasy',
        label: 'Empresa',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'price',
        label: 'Valor do Contrato',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'price_commitment',
        label: 'Valor Empenhado',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'interest__name',
        label: 'Prioridade',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
      {
        key: 'nf_paid',
        label: 'NF Paga',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap orderTableColumn',
      },
    ]

    const dataList = []
    const userImages = []
    const lastSearch = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('order')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    const meta = new MetaData('OrderTable')

    return {
      totalItems,
      totalPages,
      currentPage,
      sortOptions,
      tableColumns,
      dataList,
      userImages,
      lastSearch,
      syncLoad,
      isLoading,
      fetchId,
      meta,
    }
  },
  computed: {
    data: {
      get() {
        return this.orderValue
      },
      set(value) {
        this.$emit('orderValueChange', value)
      },
    },
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }
        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, i) => {
          if (obj.key === element.key) {
            this.tableColumns[i].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const filterOrder = this.filter

      filterOrder.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        filterOrder.ordering = `-${this.sortOptions.sortBy}`
      } else {
        filterOrder.ordering = this.sortOptions.sortBy
      }
      syslic
        .order
        .fetchOrderList(filterOrder)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.totalPages = Math.ceil(response.data.count / this.filter.page_size)
            this.dataList = response.data.results

            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('order')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos pedidos.',
                text: 'Não foi possível ler os dados dos pedidos do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('order')
          }
        })
    },
    deleteOrder(item) {
      if (item.has_delivery_done) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao remover pedido.',
            text: 'Não foi possível remover o pedido, pois ele já possui entrega ou empenho concluído.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
      } else {
        syslic
          .order
          .deleteOrder(item.id)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Pedido removido com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.dataList.splice(this.dataList.indexOf(item), 1)
            this.fetchData()
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao remover pedido.',
                text: 'Não foi possível remover o pedido do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
      }
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o pedido. Está ação também irá remover os empenhos e entregas vinculados a este pedido.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteOrder(item)
          }
        })
    },
    onRowSelectedForDetail(item) {
      this.data = item
      this.$emit('openDetail')
    },
    onRowSelectedForEdit(item) {
      this.data = item
      this.$emit('openEdit')
    },
    fetchOrdersPage(page) {
      const orderFilter = this.filter
      orderFilter.p = page
      return new Promise((resolve, reject) => {
        syslic
          .order
          .fetchOrderList(orderFilter)
          .then(response => {
            resolve(response.data.results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async recursiveFetch({ page, data }) {
      const ordersPage = await this.fetchOrdersPage(page)
      const fetchProgress = `${Math.ceil((page / this.totalPages) * 100)} %`

      data.push(...ordersPage)
      this.$emit('updateExportProgress', fetchProgress)

      if (page < this.totalPages) {
        return this.recursiveFetch({ page: page + 1, data })
      }
      return data
    },
    exportOrdersCSV() {
      if (!(this.dataList.length > 0)) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Não há dados para exportar.',
            text: 'Não há dados para exportar, por favor altere o seu filtro.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
        return
      }

      this.$emit('openLoading')

      const dataExport = []

      this.recursiveFetch({ page: 1, data: [] })
        .then(response => {
          response.forEach(order => {
            const orderItem = {
              ID: order.id,
              'Orgão/Instituição': order.contract_set.bidding_set.client_set.name,
              UASG: order.contract_set.bidding_set.uasg,
              'N° Pregão': order.contract_set.bidding_set.trade_number,
              Empresa: '',
              Valor: order.price,
              Prioridade: order.interest_set.name,
              'NF Paga': '',
              Responsavel: '',
              'Validade do Pedido': '',
            }

            orderItem.Empresa = order.company_set ? order.company_set.name_fantasy : ''
            orderItem['NF Paga'] = order.nf_payed ? 'SIM' : 'NÃO'
            orderItem.Responsavel = order.owner_set ? order.owner_set.get_full_name : ''

            if (order.date_expiration) {
              orderItem['Validade do Pedido'] = moment(order.date_expiration).format('DD/MM/YYYY')
            }

            dataExport.push(orderItem)
          })

          const fileName = 'exportação_pedidos'
          const exportType = exportFromJSON.types.csv

          exportFromJSON({ data: dataExport, fileName, exportType })

          setTimeout(() => {
            this.$emit('closeLoading')
            this.$emit('openSuccess')
            this.$emit('finishExport')
          }, 1000)
        })
        .catch(() => {
          this.$emit('closeLoading')
          this.$emit('openUnsuccess')
          this.$emit('finishExport')
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.orderTableColumn {
  min-width: 100px;
}

.orderTableAction {
  max-width: 5rem;
}

.orderTableAction .dropdown-toggle {
  padding-left: 0px;
}

.orderOwnerField {
  margin-top: 0.5rem;
}

.orderTableView {
  border-radius: 5px;
}

.sizeMin{
  min-width: 110px
}
</style>
