<template>
  <validation-provider
    #default="{ errors }"
    name="owner"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="labelField"
      label-for="owner"
    >
      <vue-autosuggest
        id="ownerInput"
        v-model="ownerSearch"
        no-filter
        :suggestions="ownersOptions"
        :input-props="{
          id: 'owner',
          ref: 'owner',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: labelField,
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getOwnerSelected"
        @input="onInputOwnerChange"
        @selected="onSelectedOwner"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <b-media>
            <template #aside>
              <b-avatar
                id="biddingOwnerImage"
                :src="userImages[suggestion.item.get_uuid]"
                size="2.5rem"
              />
            </template>
            <b-row>
              <b-col>
                <h5 class="mb-0 d-flex">
                  {{ suggestion.item.first_name }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <small class="">
                  {{ suggestion.item.permission_set.name }}
                </small>
              </b-col>
            </b-row>
          </b-media>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>

import {
  VueAutosuggest,
} from 'vue-autosuggest'

import {
  BRow,
  BCol,
  BFormGroup,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BMedia,
    BAvatar,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'ownerValue',
    event: 'ownerChange',
  },
  props: {
    ownerValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelField: {
      type: String,
      required: false,
      default: 'Responsável',
    },
    onlyActive: {
      type: Boolean,
      required: false,
      default: null,
    },
    onlyAdmin: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    const ownersOptions = []
    const ownerSearch = ''
    const limitElements = 20
    const userImages = []

    return {
      limitElements,
      ownersOptions,
      required,
      ownerSearch,
      userImages,
    }
  },
  computed: {
    owner: {
      get() {
        return this.ownerValue
      },
      set(value) {
        this.$emit('ownerChange', value)
      },
    },
  },
  watch: {
    ownerValue: function updateOwner(owner) {
      if (owner) {
        this.ownerSearch = this.getOwnerSelected({ item: owner })
      } else {
        this.ownerSearch = ''
      }
    },
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
    onSelectedOwner(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.owner = selected.item
    },
    getOwnerSelected(suggestion) {
      return `${suggestion.item.get_full_name}`
    },
    onInputOwnerChange(text) {
      syslic
        .user
        .fetchUsers({
          q: text,
          p: 1,
          page_size: this.limitElements,
          is_active: this.onlyActive,
          is_admin: this.onlyAdmin,
        })
        .then(response => {
          const userList = []

          response.data.results.forEach(obj => {
            userList.push(obj.profile)
          })

          this.ownersOptions = [{
            data: userList,
          }]

          return this.loadUsersPhotos(userList)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    loadUsersPhotos(users) {
      const promiseList = []
      users.forEach(user => {
        if (!(user.get_uuid in this.userImages)) {
          const promise = new Promise((resolve, reject) => {
            syslic.user.getUserImage(user.get_uuid)
              .then(response => {
                this.userImages[user.get_uuid] = response
                resolve()
              })
              .catch(() => {
                reject()
              })
          })

          promiseList.push(promise)
        }
      })

      return Promise.allSettled(promiseList)
    },
    onBlur() {
      if (this.owner) {
        const ownerSelected = this.getOwnerSelected({ item: this.owner })
        if (this.ownerSearch !== ownerSelected) {
          this.owner = ''
        }
      } else {
        this.ownerSearch = ''
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
