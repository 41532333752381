<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="pr-2 pl-2">
        <b-row class="d-flex justify-content-between">
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mt-1 mb-1"
          >
            <div>
              <b-form-group
                label="Exibir Linhas"
                label-for="idPerPage"
                class="mb-0"
              >
                <v-select
                  id="idPerPage"
                  v-model="pageSize"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                >
                  <span slot="no-options">Não há opções para essa quantidade.</span>
                </v-select>
              </b-form-group>
            </div>
            <div class="ml-1 w-100">
              <b-form-group
                label="Buscar na Tabela"
                label-for="idSearchBox"
                class="mb-0"
              >
                <b-form-input
                  id="idSearchBox"
                  v-model.lazy="filterOptions.search"
                  placeholder="Buscar"
                  type="text"
                  class="d-inline-block"
                />
              </b-form-group>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
            >
              <div>
                <b-dropdown
                  id="idDropdownSelectColumns"
                  class="dropBtnTableToolbar mt-2 ml-50"
                  variant="link"
                  no-caret
                  offset="-150"
                  :boundary="'scrollParent'"
                >

                  <template #button-content>
                    <b-button
                      v-b-tooltip.hover
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="md"
                      title="Exibir colunas"
                    >
                      <svg-icon
                        type="mdi"
                        size="22"
                        :path="mdiTableEdit"
                      />
                    </b-button>
                  </template>
                  <b-dropdown-text
                    class="showColumnsDropdown"
                  >
                    Exibir Colunas
                  </b-dropdown-text>

                  <b-dropdown-divider />
                  <b-dropdown-form>
                    <b-form-checkbox
                      v-for="(column, index) in ajustableFields"
                      :key="index"
                      v-model="tableColumns[column.index].visible"
                      :value="true"
                      :unchecked-value="false"
                      class="mb-1"
                    >
                      {{ column.label }}
                    </b-form-checkbox>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-end mt-1 mb-1"
          >
            <div
              class="d-flex align-items-center justify-content-center"
            >
              <div>
                <b-dropdown
                  id="idBtnDropdownFilter"
                  :text="filterOptions.id !== '' ? getFilterName() : 'Meus Filtros'"
                  class="mt-2 w-100"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(obj, index) in filterList"
                    v-show="filterList.length > 0"
                    :key="index"
                    @click="selectFilter(index)"
                  >
                    <svg-icon
                      class="mr-1"
                      type="mdi"
                      size="18"
                      :path="(filterOptions.id === obj.id ) ? mdiCircle : mdiCircleOutline"
                    />
                    {{ obj.name }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-show="filterList.length === 0"
                  >
                    Não existem filtros cadastrados
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item
                    v-show="filterList.length > 0"
                    @click="clearFilter()"
                  >
                    Sem Filtro
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center mt-2 ml-1"
            >
              <div>
                <b-button
                  v-b-tooltip.hover
                  class="btn-icon rounded-circle"
                  variant="primary"
                  size="sm"
                  title="Filtro Avançado"
                  @click="toggleFilter()"
                >
                  <svg-icon
                    type="mdi"
                    size="20"
                    :path="mdiFilterPlusOutline"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        v-show="isFilterOpen"
        class="filterOptionsForm p-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-top justify-content-end"
          >
            <div class="w-100">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <client-select
                    id="client"
                    v-model="filterOptions.client_set"
                    :label="'Órgão'"
                    @ready="syncLoad.ready('client')"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="UASG"
                    label-for="uasg"
                  >
                    <b-form-input
                      id="uasg"
                      v-model="filterOptions.uasg"
                      name="uasg"
                      placeholder="UASG"
                      @input="$emit('filter-change', filterOptions)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Nº Pregão"
                    label-for="trade_number"
                  >
                    <b-form-input
                      id="trade_number"
                      v-model="filterOptions.trade_number"
                      name="trade_number"
                      placeholder="Nº Pregão"
                      @input="$emit('filter-change', filterOptions)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <company-select
                    id="company"
                    v-model="filterOptions.company_set"
                    @ready="syncLoad.ready('company')"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <interest-select
                    id="interest"
                    v-model="filterOptions.interest_set"
                    @ready="syncLoad.ready('interest')"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <nf-payed-select
                    id="nfPayed"
                    v-model="filterOptions.nf_payed"
                    @ready="syncLoad.ready('nf-payed')"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <owner-select
                    id="owner"
                    v-model="filterOptions.owner_set"
                    @ready="syncLoad.ready('owner')"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Início da Validade"
                    label-for="dateExpirationGte"
                  >
                    <b-form-datepicker
                      id="dateExpirationGte"
                      v-model="filterOptions.date_expiration_gte"
                      locale="pt-BR"
                      size="md"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-no-date-selected="Nenhuma data foi selecionada"
                      selected-variant="primary"
                      placeholder="Selecione uma data"
                      label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                      reset-button
                      :reset-value="null"
                      reset-button-variant="info"
                      label-reset-button="Limpar"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Término da Validade"
                    label-for="dateExpirationLte"
                  >
                    <b-form-datepicker
                      id="dateExpirationLte"
                      v-model="filterOptions.date_expiration_lte"
                      locale="pt-BR"
                      size="md"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-no-date-selected="Nenhuma data foi selecionada"
                      selected-variant="primary"
                      placeholder="Selecione uma data"
                      label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                      reset-button
                      :reset-value="null"
                      reset-button-variant="info"
                      label-reset-button="Limpar"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="ml-1">
              <b-button
                v-if="(filterOptions.id !== '') && (hasPermissionToWrite)"
                v-b-tooltip.hover
                class="btn-icon rounded-circle"
                variant="flat-info"
                title="Remover Filtro"
                @click="confirmDeleteFilter()"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="hasPermissionToWrite"
        >
          <b-col
            class="d-flex justify-content-end buttonsSaveCancel"
          >
            <b-button
              class="filterBtn mr-auto"
              variant="outline-primary"
              @click="exportFilter()"
            >
              <svg-icon
                type="mdi"
                size="22"
                :path="mdiFileExportOutline"
              />
              Exportar Dados Filtrados
            </b-button>
            <b-button
              class="filterBtn"
              variant="primary"
              @click="saveFilter()"
            >
              Salvar
            </b-button>
            <b-button
              class="filterBtn ml-1"
              variant="outline-primary"
              @click="cancelFilter()"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalFilter"
        ref="refModalFilter"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(submit)"
      >
        <template #modal-title>
          Cadastro de Filtro
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="filterOptions.name"
                    name="name"
                    placeholder="Nome"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownText,
  BDropdownForm,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormDatepicker,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  mdiTableEdit,
  mdiFilterPlusOutline,
  mdiCircle,
  mdiCircleOutline,
  mdiTrashCanOutline,
  mdiFileExportOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import moment from 'moment'
import vSelect from 'vue-select'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import ClientSelect from '@/components/client/ClientSelect.vue'
import CompanySelect from '@/components/company/CompanySelect.vue'
import InterestSelect from '@/components/order/InterestSelect.vue'
import NfPayedSelect from '@/components/order/NfPayedSelect.vue'
import OwnerSelect from '@/components/user/OwnerSelect.vue'
import syslic from '@/syslic'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BDropdownForm,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker,
    BModal,
    SvgIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ClientSelect,
    CompanySelect,
    InterestSelect,
    OwnerSelect,
    NfPayedSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  props: {
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      required: false,
      default: () => [10, 25, 50, 100],
    },
    visibleColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['ready'],

  data() {
    const filterEmpty = {
      id: '',
      trade_number: '',
      name: '',
      uasg: '',
      page_size: this.perPage,
      search: '',
      company_set: '',
      interest_set: '',
      owner_set: '',
      nf_payed: null,
      date_expiration_gte: null,
      date_expiration_lte: null,
    }
    const filterList = []

    const filterOptions = JSON.parse(JSON.stringify(filterEmpty))

    const tableColumns = []

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('client')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const isFilterOpen = false

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    const meta = new MetaData('OrderFilter')

    return {
      refFormObserver,
      getValidationState,
      filterOptions,
      filterList,
      filterEmpty,
      tableColumns,
      syncLoad,
      isLoading,
      isFilterOpen,
      meta,
    }
  },
  computed: {
    pageSize: {
      get() {
        return this.filterOptions.page_size
      },
      set(value) {
        this.filterOptions.page_size = value
        this.refreshFilter()
      },
    },
    pageSearch: {
      get() {
        return this.filterOptions.search
      },
      set(value) {
        this.filterOptions.search = value
        this.refreshFilter()
      },
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  watch: {
    tableColumns: {
      handler: function updateColumns(val) {
        this.$emit('columns-change', val)
      },
      deep: true,
    },
    filterOptions: {
      handler: function updateFilter() {
        this.refreshFilter()
      },
      deep: true,
    },
  },
  created() {
    this.loadFilters()
    this.tableColumns = this.visibleColumns

    this.filterOptions = this.meta.bind(this.filterOptions, 'filterOptions')
    this.tableColumns = this.meta.bind(this.tableColumns, 'tableColumns')
  },
  methods: {
    loadFilters() {
      syslic
        .order
        .filter
        .fetchFilters()
        .then(response => {
          if (response.data.results.length > 0) {
            this.filterList = response.data.results
          }
          this.syncLoad.ready('filter')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('filter')
        })
    },
    refreshFilter() {
      if (this.checkDate()) {
        const filter = this.getFilter(this.filterOptions)

        this.$emit('filter-change', filter)
      }
    },
    exportFilter() {
      this.$emit('export-filter')
    },
    validateDateRange(startDate, endDate, message) {
      if (startDate && endDate) {
        if (startDate > endDate) {
          this.$swal({
            title: 'Atenção!',
            text: message,
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000,
          })
        }
        return false
      }
      return true
    },
    checkDate() {
      let valid = true

      const {
        date_expiration_gte, date_expiration_lte,
      } = this.filterOptions

      valid = this.validateDateRange(
        date_expiration_gte,
        date_expiration_lte,
        'A data de término da validade não pode ser anterior a data de início.',
      )

      return valid
    },
    getFilter(val) {
      const {
        id,
        name,
        search,
        page_size,
        client_set,
        company_set,
        interest_set,
        owner_set,
        trade_number,
        uasg,
        nf_payed,
        date_expiration_gte,
        date_expiration_lte,
      } = val

      const nullIfEmpty = value => (value === '' ? null : value)

      const filter = {
        id,
        name,
        page_size,
        search,
        client: client_set?.id || '',
        company: company_set?.id || '',
        interest: interest_set?.id || '',
        owner: owner_set?.id || '',
        trade_number,
        uasg,
        nf_payed: nf_payed ? nf_payed.value : null,
        date_expiration_gte: nullIfEmpty(date_expiration_gte),
        date_expiration_lte: nullIfEmpty(date_expiration_lte),
      }

      return filter
    },
    clearFilter() {
      const { page_size, search } = this.filterOptions
      this.filterOptions = JSON.parse(JSON.stringify(this.filterEmpty))

      /* eslint camelcase: ["off"] */
      this.filterOptions.page_size = page_size
      this.filterOptions.search = search

      this.isFilterOpen = false
    },
    selectFilter(index) {
      const { page_size, search } = this.filterOptions

      this.filterOptions = JSON.parse(JSON.stringify(this.filterList[index]))

      /* eslint camelcase: ["off"] */
      this.filterOptions.page_size = page_size
      this.filterOptions.search = search
    },
    getFilterName() {
      const stop = 25
      const text = this.filterOptions.name
      return text.slice(0, stop) + (stop < text.length ? '...' : '')
    },
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },
    cancelFilter() {
      this.filterList.forEach((element, index) => {
        if (element.id === this.filterOptions.id) {
          this.selectFilter(index)
        }
      })
      this.toggleFilter()
      this.clearFilter()
    },
    saveFilter() {
      if (this.checkDate()) {
        this.$refs.refModalFilter.show()
      }
    },
    submit() {
      if (this.checkDate()) {
        const filter = this.getFilter(this.filterOptions)
        if (filter.id === '') {
          this.createFilter(filter)
        } else {
          this.updateFilter(filter)
        }
      }
    },
    createFilter(filter) {
      syslic
        .order
        .filter
        .addFilter(filter)
        .then(response => {
          this.filterList.push(response.data)
          this.selectFilter((this.filterList.length - 1))

          if (this.filterList[0].id === '') {
            this.filterList.splice(0, 1)
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          let textError = 'Não foi possível criar o filtro, por favor entre em contato com o administrador do sistema.'
          let variantType = 'danger'

          if (err.data !== undefined) {
            if (err.data.includes('duplicate key value violates unique constraint')) {
              textError = 'Não foi possível criar este filtro pois já existe um filtro cadastrado com o mesmo nome.'
              variantType = 'warning'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao criar filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: variantType,
              timeout: false,
            },
          })
        })
    },
    updateFilter(filter) {
      syslic
        .order
        .filter
        .updateFilter(filter.id, filter)
        .then(response => {
          this.filterList.forEach((element, index) => {
            if (element.id === response.data.id) {
              this.filterList[index] = response.data
            }
          })

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          let textError = 'Não foi possível atualizar o filtro, por favor entre em contato com o administrador do sistema.'

          if (err.data.name !== undefined) {
            if (err.data.name.includes('order filter with this name already exists.')) {
              textError = 'Não foi possível atualizar este filtro pois já existe um filtro cadastrado com o mesmo nome.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    deleteFilter() {
      syslic
        .order
        .filter
        .deleteFilter(this.filterOptions.id)
        .then(() => {
          this.filterList.forEach((obj, index) => {
            if (obj.id === this.filterOptions.id) {
              this.filterList.splice(index, 1)
            }
          })

          this.clearFilter()

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          const textError = 'Não foi possível remover o filtro, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    confirmDeleteFilter() {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o filtro.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteFilter()
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      moment,
      mdiTableEdit,
      mdiFilterPlusOutline,
      mdiCircle,
      mdiCircleOutline,
      mdiTrashCanOutline,
      mdiFileExportOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.filterOptionsForm {
  background-color: #FAFAFC;
  border-top: 1px solid #d8d6de;
}

.filterBtn {
  min-width: 7.5rem;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#idDropdownSelectColumns .btn.dropdown-toggle {
  padding: 0px !important;
}

#idBtnDropdownFilter {
  min-width: 20rem;
}

#idModalFilter .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
