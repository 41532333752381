<template>
  <validation-provider
    #default="{ errors }"
    name="contract"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="title"
      label-for="contract"
    >
      <vue-autosuggest
        v-model="contractSearch"
        no-filter
        :suggestions="contractsOptions"
        :input-props="{
          id: 'contract',
          ref: 'contract',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: title,
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getContractSelected"
        @input="onInputContractChange"
        @selected="onSelectedContract"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <span><b>Contrato:</b> {{ suggestion.item.number }}</span><br>
          <span><b>UASG:</b> {{ suggestion.item.bidding_uasg }}</span><br>
          <span><b>Nº pregão:</b> {{ suggestion.item.bidding_trade_number }}</span><br>
          <span><b>Órgão:</b> {{ suggestion.item.client_set.name }}</span>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>

import {
  VueAutosuggest,
} from 'vue-autosuggest'

import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'contractValue',
    event: 'contractChange',
  },
  props: {
    contractValue: {
      type: [Object, String],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Contrato',
    },
    filter: {
      type: Object,
      required: false,
      default: () => ({
        bidding: '',
        status: '',
        scope: '',
        number: '',
        released: '',
        date_start: '',
        date_finish: '',
      }),
    },
  },
  data() {
    const contractsOptions = []
    return {
      limitElements: 20,
      contractsOptions,
      required,
      contractSearch: '',
    }
  },
  computed: {
    contract: {
      get() {
        return this.contractValue
      },
      set(value) {
        this.$emit('contractChange', value)
      },
    },
  },
  watch: {
    contractValue: function updateContract(contract) {
      if (contract) {
        this.contractSearch = this.getContractSelected({ item: contract })
      } else {
        this.contractSearch = ''
      }
    },
  },
  created() {
    if (this.contractValue) {
      this.contractSearch = this.getContractSelected({ item: this.contractValue })
    }
    this.$emit('ready', true)
  },
  methods: {
    onSelectedContract(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.contract = selected.item
      this.$emit('change', selected.item)
    },
    getContractSelected(suggestion) {
      return `Contrato: ${suggestion.item.number} - Nº Pregão: ${suggestion.item.bidding_trade_number} - ${suggestion.item.client_set.name}`
    },
    onInputContractChange(text) {
      const filterContract = this.filter
      filterContract.search = text
      filterContract.p = 1
      filterContract.page_size = this.limitElements

      syslic
        .contract
        .fetchContractList(filterContract)
        .then(response => {
          this.contractsOptions = [{
            data: response.data.results,
          }]
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onBlur() {
      if (this.contract) {
        const contractSelected = this.getContractSelected({ item: this.contract })
        if (this.contractSearch !== contractSelected) {
          this.contract = ''
        }
      } else {
        this.contractSearch = ''
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
