<template>
  <div>
    <div v-show="isLoading && isOpenModal">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalOrder"
          ref="modalOrder"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="lg"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          :ok-disabled="invalid"
          @ok="handleSubmit(save)"
          @cancel="cancel()"
          @hidden="cancel()"
        >
          <template #modal-title>
            Novo Pedido
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Vincular
          </template>
          <b-form
            class="mt-1"
          >
            <b-row
              class="px-2"
            >
              <b-col
                cols="12"
                md="8"
              >
                <contract-select
                  id="contract"
                  v-model="data.contract_set"
                  :required-field="true"
                  :filter="filterContract"
                  title="Selecione um Contrato Liberado"
                  @ready="syncLoad.ready('contract')"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <interest-select
                  id="idOrderInterest"
                  v-model="data.interest_set"
                  :required-field="true"
                  @ready="syncLoad.ready('interest')"
                />
              </b-col>
            </b-row>
            <h6
              class="my-0 pl-1 py-50"
              :style="{'background-color': '#E3EDF3', 'color' : '#005C96', 'font-weight' : 'bolder'}"
            >
              Endereço de Entrega
            </h6>
            <b-row>
              <b-col class="px-0">
                <address-edit-fields
                  :address="data"
                  class="mx-1"
                  :is-view-mode="false"
                  :required-fields="[]"
                  :enabled-fields="enabledFieldsAddress"
                  :zip-code-field="'zip_code'"
                  :address-field="'address'"
                  :number-field="'number'"
                  :complement-field="'complement'"
                  :neighborhood-field="'neighborhood'"
                  :country-field="'country'"
                  :state-field="'state'"
                  :city-field="'city'"
                  @ready="syncLoad.ready('address')"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
    <div>
      <b-button
        id="addOrderBtn"
        v-b-tooltip.hover
        v-b-modal.idModalOrder
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :disabled="!hasPermissionToWrite"
        title="Adicionar Pedido"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPlus,
} from '@mdi/js'

import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import AddressEditFields from '@/components/address/AddressEditFields.vue'
import InterestSelect from '@/components/order/InterestSelect.vue'
import ContractSelect from '@/components/contract/ContractSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationObserver,
    SvgIcon,
    SpinLoader,
    AddressEditFields,
    InterestSelect,
    ContractSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'orderValue',
    event: 'orderValueChange',
  },
  props: {
    orderValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    const isOpenModal = false
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('interest')
    syncLoad.addTrigger('contract')
    syncLoad.addTrigger('address')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
    ]

    const filterContract = {
      state: 'released',
    }

    return {
      refFormObserver,
      getValidationState,
      isOpenModal,
      isLoading,
      syncLoad,
      enabledFieldsAddress,
      filterContract,
    }
  },
  computed: {
    data: {
      get() {
        return this.orderValue
      },
      set(value) {
        this.$emit('orderValueChange', value)
      },
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  watch: {
    'data.interest_set': function updateInterest(val) {
      if (val) {
        this.data.interest = val.id
      } else {
        this.data.interest = ''
        this.data.interest_set = ''
      }
    },
    'data.contract_set': function updateContract(val) {
      if (val) {
        this.data.contract = val.id
        this.data.company = val.company
      } else {
        this.data.contract = ''
        this.data.contract_set = ''
        this.data.company = ''
      }
    },
  },
  methods: {
    openModal() {
      this.isOpenModal = true
      this.$refs.modalOrder.show()
    },
    save() {
      this.$emit('save')
    },
    cancel() {
      this.isOpenModal = false
      this.data = {
        address: '',
        contract: '',
        contract_set: '',
        city: '',
        city_set: '',
        company: '',
        company_set: '',
        complement: '',
        country: '',
        country_set: '',
        interest: '',
        interest_set: '',
        neighborhood: '',
        number: '',
        owner: '',
        owner_set: '',
        state: '',
        state_set: '',
        zip_code: '',
      }
      this.$emit('isAdd', true)
    },
  },
  setup() {
    return {
      mdiPlus,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#addOrderBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

 #idModalOrder {
  .modal-body{
    padding: 0 !important;
  }
  .modal-header .modal-title{
    font-size: 1.2em;
    color: $primary !important;
    font-weight: bolder;
  }
 }

 #idModalOrder .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

[dir] .modal-footer {
  border: 0px !important;
  background-color: #fff !important;
}
</style>
