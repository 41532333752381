<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <order-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @export-filter="onExportFilter"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <order-table
            ref="refOrderTable"
            v-model="data"
            :filter="filter"
            :active="true"
            @ready="syncLoad.ready('table')"
            @openDetail="openOrderDetail"
            @openLoading="openModalExportSpinner"
            @closeLoading="closeModalExportSpinner"
            @openSuccess="openModalExportSuccess"
            @openUnsuccess="openModalExportUnsuccess"
            @updateExportProgress="updateProgress"
            @finishExport="resetProgress"
            @openEdit="openOrderEdit"
          />
        </b-col>
      </b-row>
      <div>
        <order-modal
          ref="refOrderModal"
          v-model="data"
          :is-add="isAdd"
          @isAdd="isAddChange"
          @save="save"
        />
      </div>
      <div>
        <b-modal
          id="idModalExportSpinner"
          ref="modalExportSpinner"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalLoading"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiFolderOpenOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 fixLayoutTextModal"
                >
                  Exportando Dados
                </span>
                <span
                  id="modalPercentage"
                  class="d-flex justify-content-center px-2 my-2"
                >
                  {{ exportPercentage }}
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="idModalExportUnsuccess"
          ref="modalExportUnsuccess"
          class="modalStyle"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalUnsuccess"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiContentSaveAlertOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
                >
                  Falha ao exportar dados para o modelo!
                </span>
                <span
                  class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
                >
                  <b-button
                    id="hide-btn"
                    class="modal-btn-fix"
                    variant="primary"
                    @click="closeModalExportUnsuccess()"
                  >
                    Ok
                  </b-button>
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="idModalExportSuccess"
          ref="modalExportSuccess"
          class="modalStyle"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalSuccess"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiContentSaveCheckOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
                >
                  Modelo Exportado com Sucesso!
                </span>
                <span
                  class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
                >
                  <b-button
                    id="hide-btn"
                    class="modal-btn-fix"
                    variant="primary"
                    @click="closeModalExportSuccess()"
                  >
                    Ok
                  </b-button>
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BModal,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiContentSaveCheckOutline,
  mdiContentSaveAlertOutline,
  mdiFolderOpenOutline,
} from '@mdi/js'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import OrderTable from '@/views/order/components/OrderTable.vue'
import OrderFilter from '@/views/order/components/OrderFilter.vue'
import OrderModal from '@/views/order/components/OrderModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BModal,
    SpinLoader,
    SvgIcon,
    OrderTable,
    OrderFilter,
    OrderModal,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const exportPercentage = '0 %'

    const data = {
      address: '',
      contract: '',
      contract_set: '',
      city: '',
      city_set: '',
      company: '',
      company_set: '',
      complement: '',
      country: '',
      country_set: '',
      interest: '',
      interest_set: '',
      neighborhood: '',
      number: '',
      owner: '',
      owner_set: '',
      state: '',
      state_set: '',
      zip_code: '',
    }
    const isAdd = true
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')
    syncLoad.addTrigger('filter')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'contract__bidding__client__name',
        label: 'Órgão / Instituição',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'contract__bidding__uasg',
        label: 'UASG',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'contract__bidding__trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'company__name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'price',
        label: 'Valor do Contrato',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'price_commitment',
        label: 'Valor Empenhado',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'interest__name',
        label: 'Prioridade',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'nf_paid',
        label: 'NF Paga',
        visible: true,
        fixedColumn: false,
      },
    ]

    return {
      isAdd,
      isLoading,
      exportPercentage,
      filter,
      data,
      tableColumns,
      syncLoad,
    }
  },
  methods: {
    updateColumns(val) {
      this.$refs.refOrderTable.refreshColumns(val)
    },
    onExportFilter() {
      this.$refs.refOrderTable.exportOrdersCSV()
    },
    updateFilter(val) {
      this.filter = val
    },
    updateProgress(val) {
      this.exportPercentage = val
    },
    resetProgress() {
      this.exportPercentage = '0 %'
    },
    openOrderDetail() {
      this.isAddChange(false)
      router.push({ name: 'order-detail', params: { id: this.data.id } }).catch(() => {})
    },
    openOrderEdit() {
      this.isAddChange(false)
      router.push({ name: 'order-edit', params: { id: this.data.id } }).catch(() => {})
    },
    isAddChange(value) {
      this.isAdd = value
    },
    openModalExportSpinner() {
      this.$refs.modalExportSpinner.show()
    },
    closeModalExportSpinner() {
      this.$refs.modalExportSpinner.hide()
    },
    openModalExportSuccess() {
      this.$refs.modalExportSuccess.show()
    },
    closeModalExportSuccess() {
      this.$refs.modalExportSuccess.hide()
    },
    openModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.show()
    },
    closeModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.hide()
    },
    save() {
      if (this.isAdd) {
        this.add(this.data)
      } else {
        this.update(this.data.id, this.data)
      }

      this.isAdd = true
    },
    add(val) {
      syslic
        .order
        .addOrder(val)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Pedido adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          router.push({
            name: 'order-edit',
            params: {
              id: response.data.id,
            },
          })
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar este pedido, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.contract[0].includes('This field must be unique.')) {
              textError = 'Já existe um pedido com o contrato selecionado.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar pedido.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: 5000,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .order
        .updateOrder(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Pedido atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$refs.refOrderTable.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar este pedido, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.contract[0].includes('This field must be unique.')) {
              textError = 'Já existe um pedido com o contrato selecionado.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar pedido.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiContentSaveCheckOutline,
      mdiContentSaveAlertOutline,
      mdiFolderOpenOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.modalStyle .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;

  justify-content: center;

  padding-bottom: 40px;
}

#modalPercentage {
  font-size: 2.4rem;
  font-weight: 600;
  color: #6e6b7b;
}
</style>
